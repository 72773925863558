<template>
    <div class="d-flex align-items-center justify-content-center box">
        
        <div class="progress">
            <div class="progress-bar bg-success" role="progressbar" :style="{width: progress+'%'}" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
        </div>

        <!-- Start -->
        <div v-if="step == 0"  class="container intro text-center">
            <div class="mb-5 mt-4">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-intro">
            </div>
            <p class="lead text-white mb-5">Partecipa al test di orientamento online e scopri il tuo talento.<br>Rispondi alle domande per trovare il corso di laurea più adatto a te.</p>
            <button class="btn btn-outline-dark rounded-0 btn-lg" @click="step = 1">Inizia adesso!</button>
        </div>

        <!-- Dati -->
        <div v-if="step == 1" class="container">
            <div class="mb-5 mt-4 mt-3 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5 text-center">Ciao, prima di iniziare abbiamo bisogno di alcune informazioni</h1>
            <form @submit.prevent="submitStep()">
                <div class="row">
                    <div class="col-12 mb-3">
                        <input type="text" class="form-control form-control-lg" placeholder="Nome *" v-model="form.first_name" required>
                    </div>
                    <div class="col-12 mb-3">
                        <input type="text" class="form-control form-control-lg" placeholder="Cognome *" v-model="form.last_name" required>
                    </div>
                    <div class="col-12 mb-3">
                        <input type="email" class="form-control form-control-lg" placeholder="E-Mail *" v-model="form.email" required>
                    </div>
                    <div class="col-12 mb-3">
                        <input type="number" class="form-control form-control-lg" placeholder="Telefono *" v-model="form.telephone" required>
                    </div>
                    <div class="col-12 mb-3 small">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="1" v-model="form.privacy" required>
                            <label class="form-check-label">
                                Autorizzo al trattamento dei dati personali per finalità connesse allo svolgimento delle attività istituzionali (obbligatorio) * 
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mb-3 small">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="1" v-model="form.marketing">
                            <label class="form-check-label">
                                Autorizzo al trattamento dei dati per invio di materiale informativo 
                            </label>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary btn-lg" :disabled="!startTest">Avvia il Test</button>
                </div>
            </form>
        </div>
        
        <!-- Domanda 1 -->
        <div v-if="step == 2" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">1 - Se domani potessi fare un viaggio, quale meta sceglieresti?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a1" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a1" :keyFor="i" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a1">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 2 -->
        <div v-if="step == 3" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">2 - Se ti trovassi in una libreria e ti offrissero un libro da portare a casa, quale sceglieresti?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a2" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a2" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a2">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 3 -->
        <div v-if="step == 4" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">3 - Qual è la tua materia preferita a scuola?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a3" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a3" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a3">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 4 -->
        <div v-if="step == 5" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">4 - Se il professore dicesse alla classe di risolvere un problema di logica, in che modo agiresti?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a4" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a4" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a4">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 5 -->
        <div v-if="step == 6" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">5 - Se avessi una giornata libera tutta per te, come la passeresti?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a5" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a5" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a5">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 6 -->
        <div v-if="step == 7" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">6 - Qual è la qualità che più ti rappresenta?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a6" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a6" />
                </div>
            </div>
            <div class="p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a6">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 7 -->
        <div v-if="step == 8" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">7 - Se potessi scegliere uno solo di questi poteri, quale sceglieresti?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a7" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a7" />
                </div>
            </div>
            <div class=" p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a7">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 8 -->
        <div v-if="step == 9" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">8 - Se potessi prendere il posto di un grande personaggio, quale sceglieresti?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a8" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a8" />
                </div>
            </div>
            <div class=" p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="step = step+1" :disabled="!form.a8">Conferma la risposta</button>
            </div>
        </div>

        <!-- Domanda 9 -->
        <div v-if="step == 10" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">9 - Qual è il tuo film o la serie tv preferita?</h1>
            <div class="row">
                <div class="col-12 col-lg-4 mb-3" v-for="(question, i) in this.questions.a9" :key="i">
                    <RadioBox :label="question.d" :value="question.v" v-model="form.a9" />
                </div>
            </div>
            <div class=" p-3 d-flex justify-content-evenly">
                <button class="btn btn-outline-dark btn-lg me-2" @click="step = step-1">Torna indietro</button>
                <button class="btn btn-primary btn-lg" @click="submitForm" :disabled="!form.a9">Vai al Risultato</button>
            </div>
        </div>

        <!-- Risultato -->
        <div v-if="step == 11" class="container text-center">
            <div class="mb-5 mt-4 text-center">
                <img src="//testorientamento.unipegaso.it/images/logo.png" class="logo-page">
            </div>
            <h1 class="mb-5">Area {{ result[result_area].area }}</h1>
            <div class="row mb-5">
                <div class="col">
                    {{ result[result_area].description }}
                </div>
            </div>
            <h4 class="mb-5">Scopri i Corsi di Laurea</h4>
            <div class="row justify-content-center ">
                <div class="col-12 col-lg-4 mb-5 results-card" v-for="(course, i) in courses" :key="i">
                    <div class="card-bg rounded">
                        <img :src="courseImage(course)" class="w-100" />
                        <div class="p-3 text-white">
                            <h5 class="d-block font-weight-bold">{{ course.title }}</h5>
                            <a :href="courseSlug(course)" class="btn btn-primary d-block mt-3" target="_blank">
                                Scopri il corso
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>

    import RadioBox from '@/components/RadioBox'
    import AOS from 'aos'; 
    import 'aos/dist/aos.css';
    import axios from 'axios'

    export default {
        components: {
            RadioBox
        },
        data() {
            return {
                step: 0,
                form: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    telephone: null,
                    privacy: 0,
                    marketing: 0,
                    a1: 0,
                    a2: 0,
                    a3: 0,
                    a4: 0,
                    a5: 0,
                    a6: 0,
                    a7: 0,
                    a8: 0,
                    a9: 0
                },
                questions: {
                    a1: [
                        { d: 'Vienna, la città natale di Sigmund Freud', v: 2 },
                        { d: 'Parigi, per assistere alle prossime Olimpiadi', v: 9 },
                        { d: 'Atene, per vedere da vicino la culla della cultura europea', v: 3 },
                        { d: 'New York, per respirare l\'aria di Wall Street', v: 4 },
                        { d: 'Granada, per vivere l\'incontro tra le culture orientali e occidentali', v: 8 },
                        { d: 'San Francisco, per connetterti al luogo più tecnologico del mondo', v: 7 },
                        { d: 'Bruxelles, per visitare i palazzi governativi del Quartiere Europeo', v: 5 },
                        { d: 'Los Angeles, per immergerti nella creatività dei set hollywoodiani', v: 1 },
                        { d: 'Bilbao, per goderti le meraviglie architettoniche del museo Guggenheim', v: 6 },
                    ],
                    a2: [
                        { d: 'La fattoria degli animali, di George Orwell', v: 2 },
                        { d: 'Open, di Andre Agassi', v: 9 },
                        { d: 'L\'umanesimo italiano. Filosofia e vita civile nel Rinascimento, di Eugenio Garin', v: 3 },
                        { d: 'Il capitale, di Karl Marx', v: 4 },
                        { d: 'Il giro del mondo in 80 giorni, di Jules Verne', v: 8 },
                        { d: 'Siate affamati, siate folli, di Steve Jobs', v: 7 },
                        { d: 'Il diritto mite, di Gustavo Zagrebelsky', v: 5 },
                        { d: 'I diari di Andy Warhol, di Andy Warhol', v: 1 },
                        { d: 'Manuale dell\'ingegnere, di Giuseppe Colombo', v: 6 },
                    ],
                    a3: [
                        { d: 'Scienze umane', v: 2 },
                        { d: 'Educazione fisica', v: 9 },
                        { d: 'Letteratura, storia e filosofia', v: 3 },
                        { d: 'Matematica ed economia', v: 4 },
                        { d: 'Geografia', v: 8 },
                        { d: 'Informatica', v: 7 },
                        { d: 'Educazione civica e diritto', v: 5 },
                        { d: 'Storia dell\'arte', v: 1 },
                        { d: 'Fisica', v: 6 },
                    ],
                    a4: [
                        { d: 'Cerchi innanzitutto di capire il punto di vista di tutti gli altri', v: 2 },
                        { d: 'Fai una passeggiata per mettere in moto il cervello', v: 9 },
                        { d: 'Studi il problema e come lo hanno risolto gli esperti della materia', v: 3 },
                        { d: 'Valuti costi e benefici di ogni soluzione', v: 4 },
                        { d: 'Cerchi di trovare un linguaggio per descrivere al meglio il problema', v: 8 },
                        { d: 'Cerchi su Google tutte le informazioni che possono essere utili per risolvere il problema', v: 7 },
                        { d: 'Proponi la soluzione che media gli interessi di tutti e poi chiedi di votare la migliore', v: 5 },
                        { d: 'Cerchi di proporre una soluzione nuova alla quale nessuno aveva pensato', v: 1 },
                        { d: 'Scomponi il problema in tante piccole sezioni, cercando di risolverle una per volta', v: 6 },
                    ],
                    a5: [
                        { d: 'Uscendo con gli amici', v: 2 },
                        { d: 'Facendo sport da solo o con i tuoi amici', v: 9 },
                        { d: 'Leggendo un libro o vedendo un documentario', v: 3 },
                        { d: 'Cercando il modo di aprire l\'azienda dei tuoi sogni', v: 4 },
                        { d: 'Pianificando il tuo prossimo viaggio', v: 8 },
                        { d: 'Curando i tuoi profili social', v: 7 },
                        { d: 'Prendendo tutte le decisioni che hai lasciato in sospeso', v: 5 },
                        { d: 'Dipingendo, disegnando, scrivendo poesie o canzoni', v: 1 },
                        { d: 'Costruendo qualcosa con le tue mani', v: 6 },
                    ],
                    a6: [
                        { d: 'L\'empatia', v: 2 },
                        { d: 'La perserveranza', v: 9 },
                        { d: 'L\'amore per la cultura', v: 3 },
                        { d: 'La razionalità', v: 4 },
                        { d: 'La multiculturalità', v: 8 },
                        { d: 'La conoscenza del digitale', v: 7 },
                        { d: 'Il senso del dovere', v: 5 },
                        { d: 'La creatività', v: 1 },
                        { d: 'L\'ottimizzazione', v: 6 },
                    ],
                    a7: [
                        { d: 'Vedi il futuro e i cambiamenti della società', v: 2 },
                        { d: 'Diventi abile in ogni sport', v: 9 },
                        { d: 'Puoi viaggiare indietro nel tempo', v: 3 },
                        { d: 'Hai successo in ogni lavoro che svolgi', v: 4 },
                        { d: 'Puoi teletrasportarti', v: 8 },
                        { d: 'Puoi controllare internet', v: 7 },
                        { d: 'Leggi nella mente delle persone', v: 5 },
                        { d: 'Sei capace di trasfromarti in ciò che vuoi', v: 1 },
                        { d: 'Puoi muovere gli oggetti con il pensiero', v: 6 },
                    ],
                    a8: [
                        { d: 'Sigmund Freud', v: 2 },
                        { d: 'Usain Bolt', v: 9 },
                        { d: 'Aristotele', v: 3 },
                        { d: 'Jeff Bezos', v: 4 },
                        { d: 'Cristoforo Colombo', v: 8 },
                        { d: 'Mark Zuckerberg', v: 7 },
                        { d: 'Sergio Mattarella', v: 5 },
                        { d: 'Banksy', v: 1 },
                        { d: 'Archimede', v: 6 },
                    ],
                    a9: [
                        { d: 'House of Cards', v: 2 },
                        { d: 'The last dance', v: 9 },
                        { d: 'Sex Education', v: 3 },
                        { d: 'I diavoli', v: 4 },
                        { d: 'The terminal', v: 8 },
                        { d: 'The Big Bang Theory', v: 7 },
                        { d: 'Le regole del delitto perfetto', v: 5 },
                        { d: 'The bold type', v: 1 },
                        { d: 'Don\'t look up', v: 6 },
                    ],
                },
                result_area: 0,
                result: {
                    1: {
                        area: 'Creativa',
                        description: 'Per te la creatività è più di una passione, è un istinto. La tua mente è sempre pronta a valutare e realizzare nuove idee, non hai paura di sperimentare percorsi inediti e di metterci la faccia. Potresti eccellere in un corso di laurea che riesca a darti gli strumenti giusti per indirizzare la tua straripante creatività verso un obiettivo definito.',
                        slug: 'creativa',
                        degrees: [
                            { title: 'Scienze e Tecnologie delle Arti, dello Spettacolo e del Cinema', university: 'Universitas Mercatorum', slug: 'scienze-e-tecnologie-delle-arti-dello-spettacolo-e-del-cinema'},
                            { title: 'Design del Prodotto e della Moda', university: 'Universitas Mercatorum', slug: 'design-del-prodotto-e-della-moda'},
                            { title: 'Comunicazione e Multimedialità', university: 'Universitas Mercatorum', slug: 'comunicazione-e-multimedialita' }
                        ]
                    },
                    2: {
                        area: 'Sociale',
                        description: 'L’essere umano ha un fascino particolare per te. Sei una persona orientata al dialogo e allo scambio di idee. Potrebbe interessarti comprendere come funziona la mente umana e come le persone interagiscono all’interno della società. Hai l’opportunità di eccellere in un corso di laurea nel quale puoi studiare l’essere umano all’interno del suo habitat naturale: la società',
                        slug: 'sociale',
                        degrees: [
                            { title: 'Scienze Politiche e Relazioni Internazionali', university: 'Universitas Mercatorum', slug: 'scienze-politiche-e-relazioni-internazionali' },
                            { title: 'Sociologia e Innovazione', university: 'Universitas Mercatorum', slug: 'sociologia-e-innovazione' },
                            { title: 'Scienze e tecniche psicologiche', university: 'Universitas Mercatorum', slug: 'scienze-e-tecniche-psicologiche' }
                        ]
                    },
                    3: {
                        area: 'Educativa',
                        description: 'La cultura è al centro dei tuoi interessi. Sei sempre pronto ad approfondire ciò che studi e sei capace di spiegarlo agli altri in modo semplice e comprensibile. Sei consapevole dell’importanza della formazione in un mondo incentrato sulla conoscenza. Potresti eccellere in un corso di laurea che valorizzi la tua capacità di studiare e approfondire tutti i rami della cultura, soprattutto quella umanistica.',
                        slug: 'educativa',
                        degrees: [
                            { title: 'Scienze dell\'Educazione e della Formazione', university: 'Università Pegaso', slug: 'scienze-educazione-e-formazione' },
                            { title: 'Lettere, Sapere Umanistico e Formazione', university: 'Università Pegaso', slug: 'lettere-sapere-umanistico-e-formazione' },
                            { title: 'Filosofia ed Etica', university: 'Università Pegaso', slug: 'filosofia-ed-etica' }
                        ]
                    },
                    4: {
                        area: 'Economica',
                        description: 'Nel tuo mondo, tutto è orientato verso un obiettivo. Sei sempre alla ricerca del modo migliore per far funzionare un progetto e non riesci ad accontentarti di risultati a metà. Vuoi tirare fuori il meglio da ogni cosa e sei capace di costruire piani ben definiti per raggiungere i tuoi obiettivi. Potresti eccellere in un corso di laurea che ti fornisca le competenze adeguate e che dia il giusto spazio alle tue doti di leader.',
                        slug: 'economica',
                        degrees: [
                            { title: 'Gestione di Impresa', university: 'Universitas Mercatorum', slug: 'gestione-di-impresa' },
                            { title: 'Economia Aziendale', university: 'Università Pegaso', slug: 'economia-aziendale' },
                            { title: 'Lingue e Mercati', university: 'Universitas Mercatorum', slug: 'lingue-e-mercati' },
                            { title: 'Ingegneria Gestionale', university: 'Universitas Mercatorum', slug: 'ingegneria-gestionale' }
                        ]
                    },
                    5: {
                        area: 'Giuridica',
                        description: 'La giustizia è al centro dei tuoi valori. Sei sempre pronto a batterti per proteggere i più deboli ma credi che il dialogo e la mediazione siano i modi migliori per trovare la soluzione a tutto. Questo perché sei consapevole che la giustizia sia l’unico vero strumento per assicurare la libertà. Potresti eccellere in un corso di laurea che ti dia le competenze giuridiche adeguate per supportare le tue idee.',
                        slug: 'giuridica',
                        degrees: [
                            { title: 'Giurisprudenza', university: 'Università Pegaso', slug: 'giurisprudenza' },
                            { title: 'Scienze Giuridiche', university: 'Universitas Mercatorum', slug: 'scienze-giuridiche' }
                        ]
                    },
                    6: {
                        area: 'Ingegneristica',
                        description: 'I tuoi progetti sono curati in ogni minimo dettaglio. Sei preciso sia nel definire i tuoi obiettivi sia nel realizzare il processo per raggiungerli. Nessuno è capace come te di notare i particolari e tutti vedono in te un punto di riferimento quando si tratta di costruire piani d’azione articolati e completi. Potresti eccellere in un corso di laurea che ti dia le competenze adeguate per valorizzare la tua straordinaria precisione e cura dei dettagli.',
                        slug: 'ingegneristica',
                        degrees: [
                            { title: 'Ingegneria Civile', university: 'Università Pegaso', slug: 'ingegneria-civile' },
                            { title: 'Ingegneria delle Infrastrutture per una Mobilità Sostenibile', university: 'Universitas Mercatorum', slug: 'ingegneria-delle-infrastrutture-per-una-mobilita-sostenibile' }
                        ]
                    },
                    7: {
                        area: 'Informatica',
                        description: 'Puoi fare tutto con la tecnologia. Il digitale ha un fascino irresistibile per te e ogni giorno scopri nuovi strumenti e nuove possibilità legate al mondo online. Sei molto attento alle novità in ambito digitale e social e tutti si rivolgono a te quando c’è bisogno di sfruttare al massimo le opportunità offerte dalle nuove tecnologie. Potresti eccellere in un corso di laurea che ti fornisca le competenze adeguate per approfondire le tue conoscenze in ambito digitale.',
                        slug: 'informatica',
                        degrees: [
                            { title: 'Ingegneria Informatica', university: 'Universitas Mercatorum', slug: 'ingegneria-informatica' },
                            { title: 'Statistica e Big Data', university: 'Universitas Mercatorum', slug: 'statistica-e-big-data' }
                        ]
                    },
                    8: {
                        area: 'Turistica',
                        description: 'Per te tutto può essere scoperto con le emozioni. Il mondo intero può essere esplorato tramite le sensazioni e ami raccontare storie che sanno affascinare in modi nuovi chi ti ascolta. Il viaggio è l’esperienza umana più importante e sai che si può viaggiare in tanti modi, sfruttando tutti i sensi a disposizione. Potresti eccellere in un corso di laurea che ti fornisce le competenze adatte per stupire gli altri con la tua sensibilità e la tua creatività.',
                        slug: 'turistica',
                        degrees: [
                            { title: 'Gastronomia, Ospitalità e Territori', university: 'Universitas Mercatorum', slug: 'gastronomia-ospitalita-e-territori' },
                            { title: 'Scienze Turistiche', university: 'Università Pegaso', slug: 'scienze-turistiche' }
                        ]
                    },
                    9: {
                        area: 'Sportiva',
                        description: 'La tua passione è il movimento. Se ti poni un obiettivo fai di tutto per raggiungerlo e credi che il segreto per realizzare i tuoi sogni sia non arrenderti mai. Ami le sfide e credi che siano il modo migliore per migliorarsi costantemente e per tirare fuori il meglio dalle persone. Lo sport ti affascina e senti che non lo abbandonerai mai. Potresti eccellere in un corso di laurea che valorizzi la tua passione per i valori che girano attorno alle varie discipline sportive.',
                        slug: 'sportiva',
                        degrees: [
                            { title: 'Scienze Motorie', university: 'Università Pegaso', slug: 'scienze-motorie' }
                        ]
                    }
                }
            }
        },
        computed: {
            progress() {
                return (this.step / 11) * 100;
            },
            startTest() {
                return this.form.first_name && this.form.last_name && this.form.email && this.form.telephone && this.form.privacy
            },
            background() {
                if (this.step == 0) return '//testorientamento.unipegaso.it/images/intro.jpg'
                else if (this.step == 11) return '//testorientamento.unipegaso.it/images/' + 'area-' + this.result[this.result_area].slug + '.jpg'
                else return ''
            },
            courses() {
                if (this.step == 11) {
                    return this.result[this.result_area].degrees
                } 
                return []
            }
        },
        methods: {
            submitStep() {
                this.step = this.step+1
            },
            shuffle(array) {
                return array.sort((a, b) => 0.5 - Math.random());
            },
            courseImage(course) {
                if (course.university == 'Universitas Mercatorum') {
                    return '//www.unimercatorum.it/app/templates/public/default/assets/images/landing/' + course.slug + '.jpg'
                } else {
                    return '//assets.unipegaso.it/images/landing/940x500/' + course.slug + '.jpg'
                }
            },
            courseSlug(course) {
                if (course.university == 'Universitas Mercatorum') {
                    return 'https://www.unimercatorum.it/corsi-di-laurea/' + course.slug
                } else {
                    return 'https:///www.unipegaso.it/corsi-di-laurea/' + course.slug
                }
            },
            async submitForm() {
                this.step = 11
                const form = this.form
                let results = [form.a1, form.a2, form.a3, form.a4, form.a5, form.a6, form.a7, form.a8, form.a9]
                let count_a1 = results.filter(x => x == 1).length
                let count_a2 = results.filter(x => x == 2).length
                let count_a3 = results.filter(x => x == 3).length
                let count_a4 = results.filter(x => x == 4).length
                let count_a5 = results.filter(x => x == 5).length
                let count_a6 = results.filter(x => x == 6).length
                let count_a7 = results.filter(x => x == 7).length
                let count_a8 = results.filter(x => x == 8).length
                let count_a9 = results.filter(x => x == 9).length
                let count_result = [count_a1, count_a2, count_a3, count_a4, count_a5, count_a6, count_a7, count_a8, count_a9]
                let max_value = Math.max.apply(Math, count_result);
                let index_max_value = count_result.indexOf(max_value)+1
                this.result_area = index_max_value
                await axios.post('https://crm-api.multiversity.click/crm/public/mailinglist', {
                    from: 'PEGASO',
                    type: 1,
                    category: 2,
                    provider: 'orientest',
                    campaign: 'orientest_salone_studente',
                    first_name: form.first_name,
                    last_name: form.last_name,
                    email: form.email,
                    telephone: form.telephone,
                    subject: 'Lauree Triennali',
                    body: 'Ho svolto il testo di orientamento online al salone dello studente ed il risultato è stato: Area ' + this.result[this.result_area].area,
                    privacy: Number(form.privacy),
                    marketing: Number(form.marketing)
                })
            }
        },
        mounted() {
            AOS.init();
            this.shuffle(this.questions.a1)
            this.shuffle(this.questions.a2)
            this.shuffle(this.questions.a3)
            this.shuffle(this.questions.a4)
            this.shuffle(this.questions.a5)
            this.shuffle(this.questions.a6)
            this.shuffle(this.questions.a7)
            this.shuffle(this.questions.a8)
            this.shuffle(this.questions.a9)
        }
    }
</script>

<style>
    body {
        font-family: 'Ubuntu', sans-serif;
        font-size:15px;
        background-color: #f5a11a;
    }

    .container {
        max-width:880px!important;
    }

    .progress {
        position:absolute;
        top:0;
        width:100%;
        height: 5px;
        border-radius: 0;
    }

    .box {
        background-size:cover;
        background-position:bottom;
        background-color:#f5a11a;
        background-repeat: no-repeat;
    }

    .logo-intro {
        width: 500px;
    }

    .logo-page {
        width:300px;
    }

    .lead {
        background-image: url('//testorientamento.unipegaso.it/images/box.png');
        width: 677px;
        height: 180px;    
        background-position: center;
        margin: 0 auto;
        padding: 45px 60px;
    }

    .btn-outline-dark {
        background-color: #fff!important;
        border-color: #fff!important;
    }

    .btn-outline-dark:hover {
        color: #2b82c5!important;
    }

    .btn-primary {
        background-color:#2b82c5!important;
    }

    .card-bg{
        background-color: #09254a;
    }

    .btn-lg{
        font-size: 1rem;
    }

    @media screen and (max-width: 576px) { 
        .lead{
            background-image: url(//testorientamento.unipegaso.it/images/box.png);
            width: 85%;
            background-repeat: no-repeat;
            padding: 30px 25px;
            font-size: 0.95rem;
        }

        .logo-intro {
            width: 85%;
        }
    }

    @media screen and (min-width: 992px ) {
        .card-bg {
            height: 20rem;
        }

        .card-bg > img {
            height: 45%;
        }

        .card-bg > .p-3 {
            height: 55%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
</style>

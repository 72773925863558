<template>
    <div class="border rounded h-100 hover-pointer text-center bg-white" :class="{checked: isChecked}">
        <div class="form-check ps-0 h-100">
            <label class="d-block h-100 p-3 pointer align-items-center justify-content-center d-flex" :for="keyFor">
                <input class="form-check-input d-none" type="radio" :value="value" @change="$emit('change', $event.target.value)" :checked="isChecked" :id="keyFor">
                {{ label }} 
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        model: {
            prop: 'modelValue',
            event: 'change'
        },
        props: {
            "label": { type: String, default: "", required:true },
            "modelValue": { default: "" },
            "value": { type: Number, default: undefined },
            'keyFor': {type: Number }
        },
        computed: {
            isChecked() {
                return this.modelValue == this.value
            }
        }
    }
</script>

<style scoped>
    .checked {
        border-color: #2b82c5!important;
        color:#fff!important;
        background-color: #2b82c5!important;
    }
    .hover-pointer:hover {
        background-color:#2b82c5!important;
        color: #fff!important;
    }
    .pointer {
        cursor:pointer;
    }
</style>